import React from 'react';
import { graphql } from 'gatsby';
import GatsbyLink from '../components/GatsbyLink'
import Layout from '../components/Layout';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import { RelatedPosts } from '../components/Posts/Related/RelatedPosts';
import { RenderShortcode } from '../components/RenderShortcode';
import { FooterTopBlock } from '../components/Acf/FooterTopBlock';
import { Image } from '../components/Image';
import { decodeEntities } from '../utils/helpers';
import { Breadcrumbs } from '../components/Breadcrumbs';
import './post.scss';

export const BlogPostTemplate = ({
  content,
  tags,
  title,
  date,
  blogSlug,
  featuredImage,
  previousPost,
  nextPost,
  location,
  author
}) => {
  return (
    <section className="single-post">
      <div className="outer">
        <div className="wrap">
          <div className="post-header">
            <Breadcrumbs location={location} />
            <h1>{decodeEntities(title)}</h1>
            <div className="meta">
              {date && <span className="date">{date}</span>}
              {author && (
                <span className="author">
                  {`By ${author?.name}`}
                </span>
              )}
            </div>
          </div>
          {featuredImage && <Image className="featured-image" position="relative" src={featuredImage} />}
          <div className="inner">
            <div className="wrap">
              <RenderShortcode content={content} />
              {tags && tags.length ? (
                <div className="taxonomy">
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map(tag => (
                      <li key={`${tag.slug}tag`}>
                        {tag.slug && <span>{tag.name}</span>}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
          <div className="post-nav">
            <div className="previous-link">
              {previousPost && <GatsbyLink className="action prev" to={previousPost.path}>Prev</GatsbyLink>}
            </div>
            <GatsbyLink className="to-blog" to={`/${blogSlug}/`}>Back to Articles</GatsbyLink>
            <div className="next-link">
              {nextPost && <GatsbyLink className="action next" to={nextPost.path}>Next</GatsbyLink>}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const BlogPost = (props) => {
  const { data, location } = props;
  const { wordpressPost: post, nextPost, previousPost, wordpressWpSettings } = data;
  if (!post) return null;
  const { title, content, featured_image_url, yoast, categories, tags, date, author } = post;
  const { blogSlug } = wordpressWpSettings;
  return (
    <Layout>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(wordpressWpSettings.title)}`}
        desc={yoast.metaDescription}
        yoast={yoast}
      />
      <BlogPostTemplate
        content={content}
        categories={categories}
        title={title}
        date={date}
        blogSlug={blogSlug}
        tags={tags}
        featuredImage={featured_image_url}
        location={location}
        nextPost={nextPost}
        previousPost={previousPost}
        author={author}
      />
      <RelatedPosts />
      <FooterTopBlock useGlobalContent />
    </Layout>
  )
}

export default Previewable(BlogPost, 'post');

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query BlogPostByID($id: String!, $nextId: String, $prevId: String) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
    }
    nextPost: wordpressPost(id: { eq: $nextId }) {
      path
    }
    previousPost: wordpressPost(id: { eq: $prevId }) {
      path
    }
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "DD MMMM, YYYY")
      featured_image_url {
        source_url
      }
      categories {
        name
        slug
      }
      tags {
        name
        slug
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
    }
  }
`
