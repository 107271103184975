import React from 'react';

export const Plant2 = (props) => {
  return (
    <svg viewBox="0 0 204 82" {...props}>
      {/* <defs>
        <path id="prefix__a" d="M.487.527h69.466V79H.487z" />
        <path id="prefix__c" d="M0 .548h66.893v80.911H0z" />
        <path id="prefix__e" d="M.087.313h66.487V79H.087z" />
      </defs> */}
      <g fill="none" fillRule="evenodd">
        <g transform="translate(134 2)">
          {/* <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask> */}
          <path
            fill="#FFC469"
            d="M56.86 2.56C50.798-.705 42.704.23 36.62 3.472c-6.08 3.241-10.49 8.422-14.286 13.721C9.116 35.644 1.52 57.193.487 79H69.54c.951-18.62.266-37.305-2.108-55.826-.995-7.774-3.004-16.536-10.571-20.613"
            // mask="url(#prefix__b)"
          />
        </g>
        {/* <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__c" />
        </mask> */}
        <path
          fill="#FFC469"
          d="M57.784 27.138C56.115 17.19 53.038 5.574 42.41 1.69c-7.386-2.699-16.248-.358-22.253 4.267C14.153 10.58 10.54 17.11 7.79 23.675.092 42.045-1.918 62.18 1.837 81.459h65.056l-9.11-54.321"
          // mask="url(#prefix__d)"
        />
        <g transform="translate(67 2)">
          {/* <mask id="prefix__f" fill="#fff">
            <use xlinkHref="#prefix__e" />
          </mask> */}
          <path
            fill="#FFC469"
            d="M43.332.763c-12.543-2.94-22.583 9.092-27.355 19.582C7.447 39.092 2.088 58.919.087 79h66.487l-7.801-54.459C57.396 14.927 54.107 3.29 43.332.763"
            // mask="url(#prefix__f)"
          />
        </g>
      </g>
    </svg>
  )
}