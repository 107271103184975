import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './GoogleMap.scss';
//import GoogleMapReact from 'google-map-react';

const isClient = typeof window !== 'undefined';
const MapMarker = ({ text, address }) => <div className="marker">
<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"></path></svg>
<span className="name"><strong>{text}</strong><br/>{address}</span></div>;

export const GoogleMapPass = ( props ) => {
  const { data } = props;
  const { locations } = data.wordpressAcfOptions.options;

  return (
    <div className="google-map">
      <div className="wrap">
        { isClient && (
          <iframe src={`${locations[0].googleMap}`} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        )}
      </div>
    </div>
  );
};



export const GoogleMap = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressAcfOptions(options: { }) {
            options {
              locations {
                googleMap
              }
            }
          }
        }
      `}
      render={data => <GoogleMapPass {...props} data={data} />}
    />
  );
}
