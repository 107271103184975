import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { RelatedPass } from './RelatedPass'

export const RelatedPosts = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          wordpressWpSettings {
            blogSlug
          },
          categoryFilter: allWordpressPost {
            nodes {
              categories {
                wordpress_id
                id
                name
                slug
                id
              }
            }
          }
          allWordpressPost(
            sort: { fields: date, order: DESC }
            filter: { slug: { ne: "gatsby-demo" } }
            limit: 2
          ) {
            edges {
              node {
                ...PostListFields
              }
            }
          }
        }
      `}
      render={data => <RelatedPass {...props} data={data} />}
    />
  );
}
