import React from 'react';

export const Plant1 = (props) => {
  return (
    <svg viewBox="0 0 501 686" {...props}>
      {/* <defs>
        <path id="prefix__a" d="M0 0h501v686H0z" />
      </defs> */}
      <g fill="none" fillRule="evenodd">
        {/* <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask> */}
        <path
          fill="#64AEB2"
          d="M208.965 1.146c47.404-9.344 82.716 40.32 68.178 80.53-14.538 40.211-52.889 119.507-50.623 139.035 2.266 19.529 24.466 50.586 35.864 33.228 11.398-17.359 50.601-136.599 100.273-170.881C412.33 48.775 483.792 36.772 497.17 70.612c13.377 33.839-8.082 82.708-67.417 104.653-59.334 21.944-130.675 74.393-153.516 113.983-22.84 39.589-47.107 74.769-25.726 88.271 21.382 13.503 44.93 3.65 80.616-51.325 35.687-54.975 93.906-115.433 129.227-76.366 35.323 39.066-10.812 114.338-62.861 141.273-52.05 26.935-144.838 73.19-175.848 97.543-31.011 24.35-44.443 50.447-27.428 60.468 17.014 10.02 45.9 4.152 67.68-26.196 21.78-30.347 90.136-76.998 116.657-37.586 26.522 39.412-27.528 125.277-109.692 131.746-82.164 6.47-73.265 49.008-73.265 49.008 4.987 42.962-15.699 6.569-42.85-17.26-27.152-23.829-161.277-.169-150.475-59.444 10.8-59.275 69.218-33.967 105.601-4.142 36.383 29.826 54.382-14.714 41.281-25.16-13.101-10.444-203.552-80.766-133.87-165.891 69.683-85.126 80.937 74.887 121.456 79.928 40.518 5.04 28.477-47.323 4.234-89.594-25.949-45.244-204.043-176.49-76.99-240.503 98.47-49.612 60.982 173.486 94.535 192.058 33.553 18.572 70.08-21.284 46.023-63.092-24.056-41.808-98.392-251.57 4.424-271.838"
          // mask="url(#prefix__b)"
        />
      </g>
    </svg>
  )
}
