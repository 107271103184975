import React, { Component } from 'react';
import PostListItem from '../../PostListItem'
import { PostListNavigation } from '../../PostListNavigation'
import './RelatedPass.scss';

class ThisSlider extends Component {
  render () {
    const { posts, siteMetadata, pathPrefix } = this.props;
    return (
      <div className="slider">
        <div className="related-posts">
          {posts && posts.map((post, slideCount) => post.node.slug !== 'gatsby' &&
            <PostListItem key={slideCount} data={post.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} showButton />
          )}
        </div>
      </div>
    )
  }
}

export const RelatedPass = (props) => {
  const { data, title = 'Related Articles', listView, contentType = 'post' } = props;
  const { allWordpressPost, categoryFilter, allWordpressWpProjects, wordpressWpSettings } = data;
  const postPass = allWordpressWpProjects || allWordpressPost
  const { edges: posts } = postPass
  const pathPrefix = contentType === 'project' ? 'projects' : wordpressWpSettings.blogSlug
  return (
    <section className={`post-feed ${contentType}-list`}>
      {listView && <PostListNavigation pathPrefix={wordpressWpSettings.blogSlug} categoryFilter={categoryFilter} />}
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            {!listView && title && <h3 className="title section-title" dangerouslySetInnerHTML={{ __html: title }} />}
            {!listView && <ThisSlider posts={posts} siteMetadata={wordpressWpSettings} pathPrefix={pathPrefix} />}
          </div>
        </div>
      </div>
    </section>

  )
}
