/* eslint-disable camelcase */
import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { Plant2, Plant3 } from '../../Svg'
import RenderContent from '../../RenderContent';
import GatsbyLink from '../../GatsbyLink'
import { decodeEntities } from '../../../utils/helpers'
import './FooterTopBlock.scss';


/**
 * Retrieve global CTA settings
 */
const useWpSettings = () => {
  const { allWordpressAcfOptions } = useStaticQuery(
    graphql`
      query WpSettings {
        allWordpressAcfOptions {
          edges {
            node {
              options {
                global_footer_top_colour_theme
                global_footer_top_action { 
                  target
                  title
                  url
                }
                global_footer_top_text_content
                global_footer_top_title
              }
            }
          }
        }
      }
    `
  )
  return allWordpressAcfOptions.edges[0].node
}

/**
 * Footer Top Block will get its ACF from Settings > Web App
 * If the component does not have useGlobalContent checked, the props will be passed
 * from the graphql fragment.
 */
export const FooterTopBlock = (props) => {
  if (!props) return <div className="error">Missing props in FooterTopBlock ACF!</div>;
  const { useGlobalContent } = props; 
  const { options } = useWpSettings()
  return (
    <section 
      className={`footer-top-block 
        theme-${options.global_footer_top_colour_theme 
          ? options.global_footer_top_colour_theme 
          : 'default'
        }
      `}
    >
      <div className="wrap footer-top-block-wrap">
        <Plant2 className="plant2" />
        <Plant3 className="plant3" />
        {useGlobalContent 
          ? <InnerContentBlock isGlobal content={options} />
          : <InnerContentBlock isGlobal={false} content={props} /> 
        }
      </div>
    </section>
  )
}

const InnerContentBlock = ({content, isGlobal = true}) => {
  if (!content) return <p>No content prop passed.</p>;
  if (isGlobal) {
    const { 
      global_footer_top_action,
      global_footer_top_text_content,
      global_footer_top_title,
    } = content;
    return (
      <div className="wrap-inner">
        <div className="wrap-inner__action block-actions">
          <GatsbyLink className="action" target={global_footer_top_action.target} to={global_footer_top_action.url}>{decodeEntities(global_footer_top_action.title)}</GatsbyLink>
        </div>
        <span className="wrap-inner-title">{global_footer_top_title}</span>
        <RenderContent content={global_footer_top_text_content} />
      </div>
    )
  } 
    const {
      footerTopBlockActions, 
      footerTopBlockTitle, 
      footerTopBlockContent
    } = content
    return (
      <div className="wrap-inner">
        {footerTopBlockActions.length > 0 && (
          <div className="wrap-inner__action block-actions">
            <GatsbyLink className="action" target={footerTopBlockActions[0].link.target} to={footerTopBlockActions[0].link.url}>{decodeEntities(footerTopBlockActions[0].link.title)}</GatsbyLink>
          </div>
        )}
        <span className="wrap-inner-title">{footerTopBlockTitle}</span>
        <RenderContent content={footerTopBlockContent} />
      </div>
    )
  
}