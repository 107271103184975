import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Image } from '../../Image'
import Carousel from '../../Carousel';
import { getUrlVars, isClient, compileQuery, slugify } from '../../../utils/helpers'
import './Gallery.scss';
import { FaTimes, FaChevronRight, FaChevronLeft } from 'react-icons/fa';

const enableBodyScroll = () => {
  if (isClient) {
    document.body.classList.remove('nav-open');
  }
}

class ThisGallery extends Component {

  state = {
    filter: 'all',
    openNavKey: null
  };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleModal(event);
  }

  toggleModal = (event, openNavKey) => {
    event.preventDefault();
    document.body.classList.remove('modal-open')
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    openNavKey && document.body.classList.add('modal-open')
    return this.setState({ openNavKey });
  }

  nivigateTo(event, value) {
    this.setState({
      filter: value
    })
  }

  render () {
    const { urls, data } = this.props;
    const { openNavKey } = this.state
    const pageAttr = getUrlVars()

    // const settings = {
    //   container:'gallery-carousel',
    //   nav: true,
    //   mouseDrag: true,
    //   items: 1,
    //   controls: true,
    //   controlsPosition:'bottom',
    //   navPosition:'bottom',
    //   autoplay:true,
    // };

    if (urls) {
      const urlArray = urls.replace(/['"]+/g, '').split(',');
      return (
        <>
          <div className="gallery-slider">
            <Carousel>
              {urlArray.map((iurl, index) => (
                <div key={index} className="slide" style={{ position: "relative" }}>
                  <div className="inside">
                    <div className="open-modal" onClick={event => this.toggleModal(event, `${index}`)}>
                      <Image className="image" src={iurl} />
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <div className="slider-modal">
            {urlArray.map((iurl, index) => {
              return (
                <div key={index}>
                  <div onClick={event => this.toggleModal(event, `${index}`)} className={`gallery-modal${openNavKey === `${index}` ? ' open' : ' closed'}`}>
                    <div className="modal-inner">
                      <div className="wrap">
                        <div className="title">Title</div>
                        <Image className="image" src={iurl} />
                        <FaTimes className="close"/>
                      </div>
                    </div>
                  </div>
                  <div className={`controls${openNavKey === `${index}` ? ' open' : ' closed'}`}>
                    {urlArray.length > index + 1 && <button className="next" onClick={event => this.toggleModal(event, `${index + 1}`)}><FaChevronRight/></button> }
                    {index > 0 && <button className="back" onClick={event => this.toggleModal(event, `${index - 1}`)}><FaChevronLeft/></button>}
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )
    }
    return null;
  }
}


export const Gallery = (props) => {
  return (
    <ThisGallery {...props} />
  )
}
