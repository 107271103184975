import React from 'react';

export const Plant3 = (props) => {
  return (
    <svg viewBox="0 0 222 66" {...props}>
      {/* <defs>
        <path id="prefix__a" d="M0 .558h13.446V51.2H0z" />
        <path id="prefix__c" d="M.471.26h12.07v56.263H.47z" />
      </defs> */}
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 13.5)">
          {/* <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask> */}
          <path
            fill="#80BD8A"
            d="M.005 5.719c.608 13.78 1.497 27.874 3.35 41.525.834 6.151 10.29 4.706 10.088-1.422C12.987 32.05 11.39 18.014 9.69 4.355 8.956-1.535-.255-.178.005 5.719"
            // mask="url(#prefix__b)"
          />
        </g>
        <path
          fill="#80BD8A"
          d="M27.001 21.371c.208 10.637.616 21.452 1.674 32.038.66 6.59 10.435 6.969 10.324.04-.17-10.647-1.02-21.442-2.041-32.04-.615-6.367-10.087-6.709-9.957-.038m22.076-.924c-.663 12.17-1.605 24.87-.71 37.029.495 6.734 10.017 6.65 10.74.04 1.328-12.124.844-24.842.618-37.03-.124-6.647-10.288-6.636-10.648-.039m34.422 36.085c1.259-12.054-.188-24.918-.715-37.023-.288-6.612-10.661-6.732-10.65-.039.02 12.6-.735 25.893 1.137 38.367.906 6.043 9.684 3.903 10.228-1.305m16.993-5.062c1.085-9.916.262-21.266-.877-31.178-.716-6.237-9.64-6.527-10.09-.037-.724 10.43-.882 22.202.726 32.536.955 6.134 9.667 3.931 10.241-1.322m11.515-33.942c.597 11.75 1.17 24.854 3.953 36.282 1.393 5.721 9.884 4.415 10.018-1.332.274-11.752-2.029-24.688-3.954-36.282-.948-5.713-10.31-4.438-10.017 1.332m27.203-3.907c.066 13.466-.822 27.378.623 40.772.722 6.69 10.202 6.917 10.75.04 1.069-13.428-.217-27.308-.53-40.772-.16-6.834-10.878-6.913-10.844-.04m22.798-1.036c.577 13.187.927 26.561 2.399 39.678.756 6.739 10.697 7.21 10.593.042-.192-13.208-1.539-26.52-2.63-39.68-.557-6.703-10.665-6.946-10.362-.04m34.837-5.053c-.026-6.676-10.31-6.726-10.388-.039-.172 14.575-.593 29.167-.412 43.742.07 5.691 7.074 7.007 10.117 2.778l.968-1.345c1.353-1.88 1-3.869-.171-5.326.176-13.267-.06-26.545-.114-39.81"
        />
        <g transform="translate(209 .5)">
          {/* <mask id="prefix__d" fill="#fff">
            <use xlinkHref="#prefix__c" />
          </mask> */}
          <path
            fill="#80BD8A"
            d="M1.817 5.205C.971 20.432-.053 36.179.781 51.411c.375 6.848 10.112 6.77 10.743.04 1.424-15.188 1.015-30.957.76-46.207-.11-6.649-10.1-6.63-10.467-.039"
            // mask="url(#prefix__d)"
          />
        </g>
      </g>
    </svg>
  )
}